// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-doctor-js": () => import("./../src/pages/about-doctor.js" /* webpackChunkName: "component---src-pages-about-doctor-js" */),
  "component---src-pages-acupuncture-js": () => import("./../src/pages/acupuncture.js" /* webpackChunkName: "component---src-pages-acupuncture-js" */),
  "component---src-pages-chinese-herbs-js": () => import("./../src/pages/chinese-herbs.js" /* webpackChunkName: "component---src-pages-chinese-herbs-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cupping-js": () => import("./../src/pages/cupping.js" /* webpackChunkName: "component---src-pages-cupping-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opening-hours-js": () => import("./../src/pages/opening-hours.js" /* webpackChunkName: "component---src-pages-opening-hours-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

